.popup-form-parent {
  height: auto;
  width: 100%;
  background-color: var(--white);
  padding: 160px 0px 40px 0px;

  .content-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3{
      text-align: center;
    }

    .popup{
      width: 100%;
      height: fit-content;
      border-radius: 10px;
      border: 3px solid #00013b;
      background: linear-gradient(45deg, #e8e6ec, #f3f2e1);
      display:flex;
      flex-direction:column;
      gap:10px;
      justify-content: center;
      align-items:flex-start;
      padding: 20px !important;
      position: relative;
      span{
        margin: none !important;
        line-height: 26px;
        text-align: left !important;
        max-width: 100% !important;
      }
      .close{
        position: absolute;
        top: 0px;
        right: 10px;
        cursor: pointer;
        font-size: 30px;
        svg{
        font-size: 30px;
        }
      }
    }
  }
  .popup-form-cont {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: var(--unit);

    flex-direction: row-reverse;
    padding: var(--unit);
    border-radius: var(--unit);
    .pricing-box {
      width: 40%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      gap: var(--unit);
      background: linear-gradient(45deg, #fffacf, #fffbf4);
      border-radius: calc(var(--unit) / 2);
      padding: 20px;

      .content {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        & > * {
          margin: -5px;
        }

        h1 {
          margin-bottom: -10px;
        }
      }
      .included {
        background: white;
        list-style-type: none;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        h3{
          font-size: 16px !important;
          margin: -10px 0px 0px 0px;
          line-height: 16px;
        }
        p {
          font-size: 16px;
          margin: 0px 0px 0px 10px;
          line-height: 18px;
          position: relative;
          text-align: left;
          &::before{
            display: block;
            content: "";
            height: 4px;
            aspect-ratio: 1;
            border-radius: 10px;
            background: var(--accent-yellow);
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);

          }
          span {
            font-size: 16px;
            color: var(--accent-yellow);
            font-weight: 900;

          }
        }
      }
      table {
        width: 100%;
        tr {
          border-bottom: 1px dashed black;

          &:last-child {
            border: none;
            td {
              font-weight: 900;
            }
          }
          td {
            padding: 10px 0px;
            font-size: 16px;
          }
          td:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }

    form {
      height: auto;
      width: 65%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
      gap: calc(var(--unit));

      .form-row {
        position: relative;

        &.select-input {
          .input_label {
            opacity: 0;
          }

          .form-input {
            /* Remove arrow in Chrome, Safari, Edge, Opera */
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: transparent; /* Optional: removes default dropdown background */

            /* For IE */
            &::-ms-expand {
              display: none;
            }
          }

          .arrow {
            position: absolute;
            right: 3%;
            top: 50%;
            transform: translateY(-50%);
            svg {
              font-size: 22px;
            }
          }
        }

        .url-label {
          height: 100%;
          width: 48%;
          background-color: var(--grey);
          position: absolute;
          top: 0;
          border-radius: calc(var(--unit) / 4);
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size:18px;
        }
        .input_label {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          color: rgba(128, 128, 128, 0.521);
          transition: all 0.3s ease;
          pointer-events: none;

          width: fit-content;

          &.active-label {
            color: var(--accent-yellow);
            top: -3%;
            background-color: var(--white);
            font-size: calc(var(--unit) - 5px);
            opacity: 1;
          }

          &.tel_label {
            left: 17%;
          }
        }

        .form-input {
          width: 100%;
          height: 50px;
          border-radius: calc(var(--unit) / 4);
          outline: none;
          padding: 10px;
          border: 1px solid var(--grey);

          &.tel_input {
            width: calc(100% - 50px);
            position: absolute;
            right: 0;
            top: 0;

            &:focus {
              + .tel_label {
                top: -3%;
              }
            }
          }

          .ant-select-selector {
            border: none;
            transition: border-color 0.3s;
            padding: 0px !important;

            .ant-select-selection-search {
              width: 50%;
              left: 0 !important;
              position: relative;
              .ant-select-selection-search-input {
                padding: 10px;

                border: 1px solid var(--grey);
                height: 50px;
                border-radius: calc(var(--unit) / 4);

                &:focus {
                  outline: 1px solid var(--accent-yellow);
                  box-shadow: none !important;
                }
              }
            }
          }

          &:focus {
            border-color: var(--accent-yellow);
            box-shadow: 0px 0px 0px 2px rgba(5, 145, 255, 0.1);

            + .input_label {
              color: var(--accent-yellow);
              top: -3%;
              background-color: var(--white);
              font-size: calc(var(--unit) - 5px);
              opacity: 1;
            }
          }
        }

        &:nth-child(1) {
          grid-area: 1 / 1 / 2 / 2;
        }
        &:nth-child(2) {
          grid-area: 1 / 2 / 2 / 4;
        }
        &:nth-child(3) {
          grid-area: 2 / 1 / 3 / 2;
        }
        &:nth-child(4) {
          grid-area: 2 / 2 / 3/ 4;
        }
        &:nth-child(5) {
          grid-area: 3 / 1 / 4 / 2;
        }
        &:nth-child(6) {
          grid-area: 3 / 2 / 4 / 4;
        }
        &:nth-child(7) {
          grid-area: 4 / 1 / 5 / 4;
        }
        &:nth-child(8) {
          grid-area: 5 / 1 / 6 / 2;
        }
        &:nth-child(9) {
          grid-area: 5 / 2 / 6 / 4;
        }
        &:nth-child(10) {
          grid-area: 6 / 1 / 7 / 2;
        }
        &:nth-child(11) {
          grid-area: 6 / 2 / 7 / 4;
        }
        &:nth-child(12) {
          grid-area: 7 / 1 / 8 / 2;
        }
        &:nth-child(13) {
          grid-area: 7 / 2 / 8 /4;
        }

        &.error {
          .form-input {
            border: 1px solid red;
          }

          .input_label {
            color: red;
          }
        }

        .country_code {
          width: 50px;
          height: 100%;
          background-color: var(--grey);
          position: absolute;
          top: 0;
          left: 0;
          border-radius: calc(var(--unit) / 4);

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .btn-tab {
        grid-area: 8 / 1 / 9 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .popup-form-parent {
    padding: 100px 20px 40px 20px;
    .popup-form-cont {
      form {
        .form-row {
          .input_label {
            font-size: calc(var(--unit) - 5px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1068px) {
  .popup-form-parent {
    .popup-form-cont {
      flex-direction: column !important;

      .pricing-box {
        width: 100%;
      }

      form {
        width: 100%;
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(14, 1fr) !important;

        .form-row {
          &:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
          }
          &:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2;
          }
          &:nth-child(3) {
            grid-area: 3 / 1 / 4 / 2;
          }
          &:nth-child(4) {
            grid-area: 4 / 1 / 5 / 2;
          }
          &:nth-child(5) {
            grid-area: 5 / 1 / 6 / 2;
          }
          &:nth-child(6) {
            grid-area: 6 / 1 / 7 / 2;
          }
          &:nth-child(7) {
            grid-area: 7 / 1 / 8 / 2;
          }
          &:nth-child(8) {
            grid-area: 8 / 1 / 9 / 2;
          }
          &:nth-child(9) {
            grid-area: 9 / 1 / 10 / 2;
          }
          &:nth-child(10) {
            grid-area: 10 / 1 / 11 / 2;
          }
          &:nth-child(11) {
            grid-area: 11 / 1 / 12 / 2;
          }
          &:nth-child(12) {
            grid-area: 12 / 1 / 13 / 2;
          }
          &:nth-child(13) {
            grid-area: 13 / 1 / 14 / 2;
          }

          .tel_label{
            left: 12% ;
          }

          .tel_input {
            width: 92% !important;
          }
        }

        .btn-tab {
          grid-area: 14 / 1 / 15 / 2;
        }
      }
    }
  }
}

.ant-select-focused + .input_label,
.form-input:focus + .input_label {
  color: var(--accent-yellow) !important;
  top: -3% !important;
  background-color: var(--white);
  font-size: calc(var(--unit) - 5px);
  opacity: 1;
}

.ant-select-focused:where(
    .css-dev-only-do-not-override-apn68
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer).ant-select-selector {
  box-shadow: none !important;
}

@media only screen and (max-width: 1000px) {
  .popup-form-parent {
    .popup-form-cont {
      padding: 0px !important;
      form {
        .form-row {
          .input_label {
            font-size: 15px !important;

            &.tel_label {
              left: 22% ;
            }
          }

          // .tel_input {
          //   width: 50% !important;
          // }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px){

 .popup-form-parent {
  .popup-form-cont {
    form{

      .form-row{
        .tel_input{
          width: calc( 100% - 60px) !important;
        }
      }

    }

    .included{
      p{
        font-size: 12px !important;
      }
    }


  }
  }
}


