.header-top {
  height: 30px;
  padding: 5px;
  background: var(--grey);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .header-top-container {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    .header-top-left {
      display: flex;
      justify-content: center;
      align-items: center;

      .left-p {
        font-size: 15px;
        margin: 0 !important;
      }
    }

    .header-top-right {
      display: flex;
      gap: 20px;

      /* Container for the dropdown */
      .dropdown-container {
        position: relative;
        display: inline-block;

        .dropdown-header {
          width: 100px;
          padding: 0px 5px;
          background-color: #f0f0f0;
          border: 1px solid #ccc;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // border: 1px solid red;
          font-size: 15px;
          border-radius: 5px;

          .dropdown-arrow {
            margin-left: 10px;
            font-size: 5px;

            &.active{
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-options {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          background-color: #fff;
          border: 1px solid #ccc;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          z-index: 10;
          font-size: 15px;
          z-index: -1;
          font-family: "Quicksand", serif;

          // .dropdown-option.active {
          //   background-color:var(--accent-blue);
          //   color: white; /* Optional for better visibility */
          // }
          .dropdown-option {
            padding: 5px;
            font-size: 15px;
            font-family: "Quicksand", serif;
            cursor: pointer;

            &:hover {
              background-color: #f0f0f0;
            }

            &.active {
              background-color: var(--accent-blue);
              color: var(--white);
            }
          }
        }
      }

      // .dropdown-arrow {
      //   font-size: 18px;
      //   /* Adjust size of the icon */
      //   color: #333;
      //   /* Set color of the icon */
      // }

      // .dropdown-arrow.open {
      //   transform: rotate(180deg);
      //   /* Rotates arrow upward */
      // }

      // /* List of dropdown items */
      // .dropdown-list {
      //   position: absolute;
      //   top: 100%;
      //   left: 0;
      //   width: 100%;
      //   background-color: #fff;
      //   border: 1px solid #ccc;
      //   border-radius: 4px;
      //   z-index: 1000;
      //   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      // }

      // /* Dropdown item */
      // .dropdown-item {
      //   padding: 10px;
      //   cursor: pointer;
      //   transition: background-color 0.2s ease;
      // }

      // /* Hover effect */
      // .dropdown-item:hover {
      //   background-color: lightgray;
      // }

      // /* Active item */
      // .dropdown-item.active {
      //   background-color: var(--accent-blue);
      //   color: white;
      // }

      // /* Prevent selection on hover */
      // .dropdown-item:active {
      //   background-color: var(--accent-yellow);
      //   color: white;
      // }

      .close-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 30px !important;
          width: 30px !important;
        }
      }
    }
  }
}

.country-flag {
  height: 20px !important;
  width: 20px !important;
}

@media only screen and (max-width: 500px) {
  .header-top {
    // height: auto;
    // padding: 10px;

    .header-top-container {
      // flex-direction: column;

      
    }
  }
}

.header {
  height: 100px;
  position: fixed;
  z-index: 100;
  transition: var(--trans);
  top: 30px;

  &.scrolled {
    background: var(--white);
    height: 60px;
    box-shadow: var(--shadow);
    transition-delay: 0.1;
    top: 30px;

    .links {
      padding: 0px 10px !important;
      background-color: transparent;
      backdrop-filter: blur(0 px) !important;
      border: none !important;
    }
  }

  &.normalHeader {
    background: var(--white);
    height: 60px;
    box-shadow: var(--shadow);
    transition-delay: 0.1;
    top: 0px;

    .links {
      padding: 0px 10px !important;
      background-color: transparent;
      backdrop-filter: blur(0 px) !important;
      border: none !important;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: fit-content;

      img {
        height: 50px;
      }
    }

    .links {
      padding: 5px;
      overflow: hidden;

      a:not(.btn) {
        padding: 10px 20px;
        transition: var(--trans);
        position: relative;
        overflow: hidden;

        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 75%;
          height: 3px;
          background: var(--accent-yellow);
          bottom: 0px;
          left: -150%;
          transform: translateX(-50%);
          transition: var(--trans);
          opacity: 0;
          border-radius: 10px;
        }

        &:hover {
          color: var(--accent-yellow);

          &::after {
            left: 50%;
            opacity: 1;
          }
        }
      }
    }

    .navbtn {
      height: 55px;
      aspect-ratio: 1;
      display: none;
      align-items: center;
      justify-content: center;
      gap: 6px;
      flex-direction: column;
      transition: var(--trans);

      &:hover {
        transform: scale(1.1);
      }

      span {
        height: 5px;
        width: 70%;
        border-radius: 100px;
        background: var(--accent-blue);
        display: block;
        content: "";
      }
    }
  }
}

.mobilenav {
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, #cdcad3, #e5e4cf);
  position: fixed;
  // top: 30px;
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    height: 55px;
    aspect-ratio: 1;
    position: fixed;
    top: 20px;
    right: 20px;
    transition: var(--trans);

    &:hover {
      transform: scale(1.1);
    }

    span {
      height: 5px;
      width: 70%;
      border-radius: 100px;
      background: var(--accent-blue);
      display: block;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .mobile-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    a {
      text-transform: uppercase;
      font-weight: 700;
    }

    a:not(.btn) {
      &:hover {
        color: var(--accent-yellow);
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .links {
    display: none;
  }

  .navbtn {
    display: flex !important;
  }
}


@media only screen and (max-width:500px){

}