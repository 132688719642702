.thank_you {
  height: 100vh;
  .thank_you_cont {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .box-content {
      width: auto;
      aspect-ratio: 2;
      background-color: var(--white);
      box-shadow: var(--shadow);
      border-radius: var(--unit);
      padding: var(--unit);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .right_icon {
        width: 50px;
        aspect-ratio: 1;
        background-color: var(--accent-yellow);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: calc(var(--unit) * 2);
          color: var(--accent-blue);
        }
      }
    }
  }
}
