.pop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10000000;

  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.801);
    backdrop-filter: blur(15px);
    height: 100%;
    width: 100%;

    &:hover {
      cursor: crosshair;
    }
  }

  .pop-container {
    max-width: 900px;
    z-index: 2;
    max-height: 620px;
    background: var(--white);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    gap: 50px;

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      // gap: 10px;
      .lists {
        width: 100%;
        padding: 0px;
        margin-left: 10px;
        overflow-y: auto;

        .list_items {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: flex-start;
          font-size: 16px !important;
          margin: 10px 0px;
          filter: saturate(0%);
          color: grey;

          &.active {
            filter: saturate(100%);
            color: black;
          }

          .icon {
            background: var(--accent-yellow);
            height: 18px;
            aspect-ratio: 1;
            border-radius: 100px;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              font-size: 10px;
            }
          }
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        p {
          margin: 0px !important;
        }
      }
    }

    .right {
      width: 50%;
      background: linear-gradient(45deg, #e8e6ec, #f3f2e1);
      border-radius: 5px;
      height: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        h1 {
          margin: 0px;
          line-height: 30px;
        }

        .duration {
          padding: 0px 15px;
          background: var(--accent-yellow);
          border-radius: 100px;
          font-size: 16px;
        }

        .included {
          background: white;
          list-style-type: none;
          padding: 10px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 100%;

          h3 {
            font-size: 16px !important;
            margin: -10px 0px 0px 0px;
            line-height: 16px;
          }

          p {
            font-size: 16px ;
            margin: 0px 0px 0px 10px;
            line-height: 18px;
            position: relative;

            &::before {
              display: block;
              content: "";
              height: 4px;
              aspect-ratio: 1;
              border-radius: 10px;
              background: var(--accent-yellow);
              position: absolute;
              top: 50%;
              left: -10px;
              transform: translateY(-50%);
            }

            span {
              font-size: 16px;
              color: var(--accent-yellow);
              font-weight: 900;
            }
          }
        }
      }

      table {
        width: 100%;

        tr {
          border-bottom: 1px dashed black;

          &:last-child {
            border: none;

            td {
              font-weight: 900;
            }
          }

          td {
            padding: 10px 0px;
            font-size: 16px;
          }

          td:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }

      .btn-box {
        display: flex;
        gap: 20px;
        align-items: center;

        .btn-2 {
          margin: 0px !important;
          width: 50%;
        }

        .btn {
          width: 50%;
        }
      }
    }
  }
}

.price {
  min-height: 100svh;

  .price-container {
    padding: 150px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .price-box {
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .plans {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 5px;
        border-radius: 100px;

        .plan {
          padding: 10px 30px;
          border-radius: 100px;
          transition: var(--trans);
          font-size: 18px;
          font-weight: 900;
          cursor: pointer;

          &:hover,
          &.active {
            background: var(--accent-yellow);
            color: var(--black);
          }
        }
      }

      .cards {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 50px;

        .card {
          // max-height: 500px; /* Adjust this height */
          // overflow-y: auto;
          width: 350px;
          box-shadow: var(--shadow);
          transition: var(--trans);
          padding: 10px;
          background: rgba(255, 255, 255, 0.9);
          border-radius: 15px;
          position: relative;
          overflow: hidden;
          padding: 50px 0px 0px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0px;

          &.active,
          &:hover {
            // border: 2px solid var(--accent-yellow);
            box-shadow: 0px 0px 50px #f1ba2127;
            transform: scale(1.05);
          }

          .card-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              font-size: 20px;
              color: var(--accent-yellow);
              margin-top: -25px !important;
            }

            .plan-type {
              background: var(--accent-yellow);
              width: fit-content;
              color: var(--accent-blue);
              padding: 5px 20px;
              border-radius: 0px 0px 15px 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              box-shadow: var(--shadow);
              font-weight: 900;
            }

            h1 {
              display: flex;
              font-size: 40px;
              align-items: center;
              gap: 2px;
              padding: 20px 0px;

              span {
                font-size: 20px !important;
                color: var(--accent-blue);
              }
            }

            /* Updated strikethrough styling */
            .strike {
              font-size: 20px !important;
              color: rgba(255, 0, 0, 0.7);
              text-decoration: line-through;
              margin-bottom: -20px;
              font-weight: 400 !important;
            }

            .tax_word {
              font-size: 15px !important;
              line-height: 100%;
              color: var(--black);
            }

            p {
              margin-top: -10px;
            }

            .lists {
              width: 100%;
              padding: 0px;
              margin-left: 10px;

              .high-box {
                display: flex;
                gap: 5px;
                // flex-direction: column;
                // background: red;
                margin: -10px 0px 20px 0px;
                width: calc(100% - 20px);
                align-items: center;
                justify-content: center;

                .highlight {
                  font-size: 14px;
                  padding: 1px 20px;
                  // background: linear-gradient(45deg ,var(--accent-yellow), #f3f2e1);
                  background: var(--accent-blue);
                  width: fit-content;
                  // margin-bottom: 10px;
                  border-radius: 100px;
                  color: var(--white);
                  font-weight: 800;
                }
              }

              .before {
                font-size: 16px;
                font-weight: 700;
                background: linear-gradient(
                  45deg,
                  var(--accent-yellow),
                  #f3f2e1
                );
                padding: 0px 10px;
                border-radius: 100px;
                width: calc(100% - 20px);
                margin: 0px 0px 10px 0px;
              }

              .plus {
                height: 25px;
                aspect-ratio: 1;

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                width: calc(100% - 20px);

                span {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px !important;
                  font-weight: 700;
                  // background: var(--accent-yellow);
                  outline: 2px solid var(--accent-yellow);
                  border-radius: 100px;
                  padding: 0px 10px;
                }
              }

              .list_items {
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: flex-start;
                font-size: 16px !important;
                margin: 10px 0px;
                filter: saturate(0%);
                color: grey;

                &.active {
                  filter: saturate(100%);
                  color: black;
                }

                .icon {
                  background: var(--accent-yellow);
                  height: 18px;
                  aspect-ratio: 1;
                  border-radius: 100px;
                  font-size: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    font-size: 10px;
                  }
                }
              }
            }
          }

          .btn {
            width: 90%;
            margin: 30px 0px 20px -10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .plans {
    gap: 10px !important;
    padding: 5px;
    border-radius: 100px;

    .plan {
      padding: 5px 10px !important;
      border-radius: 100px;
      transition: var(--trans);
      font-size: 14px !important;
      font-weight: 900;

      &:hover,
      &.active {
        background: var(--accent-yellow);
        color: var(--black);
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .pop-container {
    flex-direction: column !important;
    height: fit-content !important;
    width: calc(100% - 60px) !important;
    overflow: scroll;

    .left {
      width: 100% !important;
    }

    .right {
      width: 100% !important;
      padding: 10px !important;
      font-size: 16px 
    }
  }
}

@media only screen and (max-width: 500px) {
  .pop-container {
    width: calc(100% - 20px) !important;

    padding: 10px !important;

    .included{
      p{
        font-size: 12px !important;
      }
    }
  }
}
