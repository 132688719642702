.hero {
  height: calc(100dvh - 100px);
  // display: none;

  .hero-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    position: relative;
    z-index: 10;

    h1 {
      text-align: center;
      padding-top: 150px;
    }

    .btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hero {
    .hero-cont {
      h1 {
        padding-top: 50px;
        font-size: 30px;
      }
    }
  }
}

.imgbox-wrapper {
  height: auto;
  padding: 50px 0px;
  // display: none;
  // border: 1px solid ;

  .imgbox-cont {
    .imgbox {
      padding: 20px;
      width: 100%;
      height: auto;
      border-radius: 20px;
      animation: rotate-in 3s ease-in-out infinite;

      @keyframes rotate-in {
        0%,
        100% {
          transform: translateY(-60px);
        }

        50% {
          transform: translateY(0px);
        }
      }

      .img-screen {
        aspect-ratio: 16/9;
        width: 100%;
        background: var(--white);
        border-radius: 10px;
        // background: url("https://images.ctfassets.net/w8fc6tgspyjz/ANJFM8HigJCMUwQxflBvU/226711f57db215ba057f50f7a9b68c37/home-tabs-v4-chat-desktop.png");
      }
    }
  }
}

// 3rd section starts here

.twocol {
  background: linear-gradient(
    90deg,
    var(--white) 50%,
    #00013b 50%,
  );
  overflow-x: hidden;
  z-index: 0;
  position: relative;
  padding: 120px 0px;

  .twocol-cont {
    display: flex;

    .left {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-right: 30px;
      gap: 5px;

      p {
        text-align-last: left;
        text-align: left;
      }
    }

    .right {
      height: 100%;
      width: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-box {
        width: 95%;
        aspect-ratio: 16/9;
        padding: 12px;
        border-radius: 20px;

        .img {
          background: var(--white);
          height: 100%;
          width: 100%;
          border-radius: 10px;
          background: url("../../assets/about.webp");
          // background: url("https://images.ctfassets.net/w8fc6tgspyjz/ANJFM8HigJCMUwQxflBvU/226711f57db215ba057f50f7a9b68c37/home-tabs-v4-chat-desktop.png");
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .twocol {
    background: linear-gradient(
      180deg,
      var(--white) 50%,
      var(--accent-yellow) 50%
    );
    padding: 50px 0px !important;
    .twocol-cont {
      flex-direction: column;
      height: 100%;
      .left {
        width: 100%;
        height: 50% !important;
        padding-right: 0px;
        align-items: center !important;
        justify-content: center !important;

        h2 {
          text-align: center;
        }
        p {
          text-align: center;
          text-align-last: center;
        }

        .btn {
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.316);
        }
      }
      .right {
        width: 100% !important;
        height: 50% !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 10px 0px !important;
        // background: red;
        .img-box {
          height: 60% !important;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.316);
          .img {
            height: 100% !important;
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .twocol {
    .twocol-cont {
      .left {
        padding-bottom: 90px;
      }
    }
  }
}

.bento-parent {
  --card-height: 400px;
  padding: 50px 0px;

  .bento-cont {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .bento {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .line {
        display: flex;
        gap: 12px;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        .left,
        .right {
          width: 40%;
          min-height: 200px;
          padding: 15px;
          background: var(--white);
          display: flex;
          flex-direction: column;
          gap: 20px;

          h3 {
            font-size: 22px;
            text-align: left;
            margin: 0;
            padding: 0;
          }
          p {
            font-size: 14px;
            text-align: left;
            text-align-last: left;
            line-height: 18px;
            margin: 0;
            padding: 0;
          }
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 5px;
            margin: 0;
            padding: 0;
          }
        }

        .right {
          width: 60%;

          p {
            max-width: 66%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .bento {
    .line {
      flex-direction: column !important;

      .right,
      .left {
        width: 100% !important;
        h3 {
          font-size: 20px !important;
          text-align: center !important;
        }

        p {
          max-width: 100% !important;
          text-align: center !important;
          text-align-last: center !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.before-footer {
  height: 500px;
  background: var(--white);
  .before-footer-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .sub-title {
      text-align: center;
      margin-bottom: 0px !important;

      span {
        font-weight: 300 !important;
      }
    }
    .title {
      text-align: center;
      line-height: 150%;
    }
  }
}

@media screen and (max-width: 900px) {
  .before-footer-cont {
    .sub-title {
      font-size: 20px;
    }

    .title {
      font-size: 20px !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .imgbox-wrapper {
    .imgbox-cont {
      .imgbox {
        padding: 10px !important;
      }
    }
  }
}
