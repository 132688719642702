// before footer
.before_footer {
  height: fit-content;
  padding: 40px 0px;
  background-color: rgb(255, 249, 163);

  .before_footer_cont {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .before_left {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      a {
        font-size: 18px;
        color: var(--accent-yellow2);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calc(var(--unit) / 2);

        .icons {
          font-size: 20px;
          padding-right: 50px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .before_right {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--unit);

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calc(var(--unit) / 2);
      }

      span {
        font-size: 20px;
        color: var(--accent-blue);

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--white);
        padding: calc(var(--unit) / 2);
        border-radius: 50%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .before_footer_cont {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 500px) {
  .before_footer_cont {
    .before_right {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }
}

// footer starts from here
.footer-parent {
  height: auto;
  background: var(--accent-blue);

  .footer-cont {
    // display: flex;
    align-items: center;

    .first-box {
      display: flex;
      width: 100%;
      padding:20px 0px;

      .left-f {
        width: 30%;

        img {
          height: 50px;
        }
      }

      .right-f {
        width: 70%;
        display: flex;
        gap: 40px;
        align-items: center;
        justify-content: flex-end;

        p {
          font-size: 16px;
          color: var(--white);
          font-weight: 600;
          margin-bottom: 0px !important;
        }

        .list {
          display: flex;
          gap: var(--unit);
          margin-bottom: 0px !important;
          list-style-type: none;

          li {
            a {
              font-size: 16px;
              font-weight: 600;
              color: var(--white);

              &:hover {
                color: var(--accent-yellow);
              }
            }
          }
        }
      }
    }
    
  }
  .second-box {
    background:black;
    display: flex;
      align-items: center;
      justify-content: center;
    .l-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0px;

      p {
        color: rgba(255, 255, 255, 0.747);
        font-size: 16px;display:flex;
        margin:0;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .footer-cont {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: var(--unit) !important;
    justify-content: center !important;

    .first-box {
      display: flex;
      flex-direction: column;
      gap: var(--unit);
      .left-f {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .right-f {
        width: 100% !important;
        display: flex;
        justify-content: center !important;
        align-items: center;

        .list {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .footer-cont {
    .first-box {
      .right-f {
        flex-direction: column !important;
        .list {
          flex-direction: column;
        }
      }
    }
  }
}

.pagetotop {
  position: fixed;
  bottom: 5%;
  right: 2%;

  .arrowbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--accent-yellow);
    width: 40px;
    aspect-ratio: 1;
    cursor: pointer;
    z-index: 99999999999;
  }
}
