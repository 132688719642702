.testimonial-parent {
    padding: 100px 10px !important;
    background-color: var(--grey);
    
    .testimonial-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--unit);

        .testimonial-left {
            display: flex;
            gap: var(--unit);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 35%;
            text-align: center;

            .testimonial-heading {
                font-size: 28px;
                line-height: 120%;
                text-align: left;
            }
        }

        .testimonial-right {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 65%;
            

            .mySwiper {
                width: 100%;
                height: 100%;
              
                  // Remove navigation arrows
                  .swiper-button-prev,
                  .swiper-button-next {
                      display: none;
                  }

                .swiper-pagination-clickable .swiper-pagination-bullet {
                    background-color: var(--accent-yellow);
                    
                }
            }


            .swiper-slide {
                width: 380px;
            }

            // .video-container {
            //     border-radius: calc(var(--unit) / 2) !important;
            //     width: 100%;
            //     overflow: hidden;
            //     margin: auto;
            //     position: relative;

            //     video {
            //         height: 100% !important;
            //         aspect-ratio: 1;
            //     }

            //     .v-box {
            //         border-radius: 10px;
            //         overflow: hidden;
            //         aspect-ratio: 1;
            //         width: 100%;
            //     }

            //     .custom-controls {
            //         position: absolute;
            //         top: 50%;
            //         left: 50%;
            //         transform: translate(-50%, -50%);
            //         z-index: 10;

            //         button {
            //             background: transparent;
            //             border: none;
            //             cursor: pointer;
            //             font-size: 36px;
            //             color: white;
            //         }

            //         .play-pause-icon {
            //             font-size: 50px;
            //             color: white;
            //             opacity: 0.8;
            //         }
            //     }
            // }

            // testimonial starts from here
            .testi-cont {
                background-color: white;
                border-radius: 10px;
                padding: 20px;
                // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                max-width: 380px;
                margin: auto;
                margin-bottom: 50px;
            
                .testi-stars {
                    color: #FFA500; // Change to your desired star color
                    font-size: 18px;
                    margin-bottom: 10px;
                }
            
                .testi-para {
                    font-size: 14px;
                    color: #555;
                    line-height: 1.6;
                    margin-bottom: 15px;
                }
            
                .testi-user {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 14px;
            
                    strong {
                        font-size: 16px;
                        color: #000;
                    }
            
                    
                }
            }
            


        }
    }
}

@media only screen and (max-width:700px) {
    .testimonial-parent {
        .testimonial-container {
            flex-direction: column;
            gap: 20px;

            .testimonial-left {
                width: 100%;
                .testimonial-heading{
                    text-align: center;
                }
            }

            .testimonial-right {
                width: 90%;

                .testi-cont {
                    background-color: white;
                    border-radius: 10px;
                    padding: 20px;
                    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    max-width: 380px;
                    margin: auto;
                    margin-bottom: 50px;
                }


            }
        }
    }
}

@media only screen and (max-width:768) {
    .testimonial-parent {

        .testimonial-container {
            flex-direction: column;
            gap: 20px;

            .testimonial-left {
                width: 100%;
                text-align: center;
            }

            .testimonial-right {
                width: 80%;
            }
        }
    }
}


@media only screen and (max-width:1024px) {
    .testimonial-parent {

        .testimonial-container {

            .testimonial-right {

                .mySwiper {
                    padding-bottom: 50px;

                }
            }
        }
    }

}

// @media only screen  and (min-width:600px) and (max-width:768px) {
//     .testimonial-parent {
//         .testimonial-container {
//             flex-direction: column;

//             .testimonial-left {
//                 width: 45% !important;
//             }

//             .testimonial-right {
//                 width: 55% !important;
//             }
//         }
//     }
// }