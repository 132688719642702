.privacy-parent{
    padding:150px 0px;
    .privacy-cont{
       p{
        max-width:100%;
        text-align: left;
        text-align-last: left;
       }
      
    }
    @media only screen and (max-width:800px){
        .privacy-cont{
            display: flex;flex-direction:column;align-items: center;justify-content: center;
            h4{
                font-size: 20px !important;
                text-align: center;
            }
            li{
                font-size: 16px;
                
            }
        }
    }
}