.term-parent{
    padding:150px 0px;
    .term-cont{
        display: flex;
        flex-direction: column;
        p{
            max-width:100%;
            text-align: left;
            text-align-last: left;
           }


    }
    @media only screen and (max-width:800px){
        .term-cont{
            display: flex;flex-direction:column;align-items: center;justify-content: center;
            h4{
                font-size: 20px !important;
                text-align: center;
            }
            li{
                font-size: 16px;
                
            }
        }
    }
}